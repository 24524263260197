import * as React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import { Card, CircularProgress, Divider, StepLabel } from "@mui/material";
import styled from "@emotion/styled";
import HomeIcon from "@mui/icons-material/Home";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import MuiSnackbar from "./UI/MuiSnackbar";
import MuiCustomModal from "./UI/MuiCustomModal";
import Home from "./Forms/Home";
import ImageUploadField from "./question-fields/ImageUploadField";
import BooleanField from "../Components/question-fields/boolean";
import DropdownField from "../Components/question-fields/dropdown";
import NoteField from "../Components/question-fields/note";
import QuestionForm from "../Components/Forms/QuestionForm";
import { QuestionFormField } from "../Components/FormBuilder/Questions";
import {
  updateProjectBacklogStatusApi,
  updateQuestion,
  uploadImage
} from "./services/finance.service";
import InvoiceTable from "./Forms/InvoiceTable";
import CustomNoteTable from "./Forms/CustomNoteTable";
import CustomDatePicker from "./question-fields/customDatePicker";
import OpenQuestions from "./Forms/OpenQuestions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MuiCheckbox from "./question-fields/MuiCheckbox";
import { decode } from "html-entities";
import {
  blueWithOP,
  defaultGray,
  getColor,
  getColorCOIBtn,
  getColorDeliveryTicketBtn,
  getColorEstDateBtn,
  getColorForButton,
  getColorProjectDataBtn,
  getColorSFRoomBtn,
  greenWithOP,
  redOP,
  redWithOP,
  yellowWithOP
} from "./utils/customeColor";
import TransitionConfirmation from "./Forms/TransitionConfirmation";
import { getDeliveryTicketStatus } from "../helpers/projectBacklogStatusHelper";
import FurnitureAudit from "./Forms/FurnitureAudit/FurnitureAudit";
import OPR from "./Forms/OPR";

let Icon = {
  "Confirmation Email Review": "Email",
  "Delivery Ticket?": "Delivery Ticket",
  "Supplier Receipt": "Supplier Receipt",
  "COI?": "COI/Building Approval",
  "Estimated Start Date": "Estimated Start Date",
  "Date NOTES": "Date Notes",
  MAGICPLAN: "Project Data",
  MATTERPORT: "Matterport",
  "Transition Confirmation Needed?": "Transition Audit",
  "SF CONFIRMATION": "Room Audit",
  "Is there furniture in the space?": "Furniture Audit",
  Invoice: "Invoice",
  "Custome Notes": "Invoice Notes",
  "Open Questions Needed?": "Open Question",
  "Operation Project Review": "Operation Project Review"
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg, #1e2e5a 0%,#263055 50%,#444c74 100%)"
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,#1e2e5a 0%,#263055 50%,#444c74 100%)"
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled("div")(
  ({ theme, ownerState, color, index, textColor = "white" }) => ({
    backgroundColor: "#ccc",
    zIndex: 1,
    color: textColor,
    width: 50,
    border: textColor === "black" ? "1px solid gray" : "none",
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    transition: "transform 0.2s ease, box-shadow 0.2s ease",
    backgroundImage: `linear-gradient(136deg, ${color} 0%, ${color} 50%, ${color} 100%)`,
    ...(ownerState.active && {
      backgroundImage:
        index == 0
          ? ""
          : `linear-gradient(136deg, ${color} 0%, ${color} 50%, ${color} 100%)`,
      boxShadow: "0px 0px 12px 10px rgba(0,0,0,.40)",
      transform: "scale(1.1)"
    }),
    ...(ownerState.completed && {
      backgroundImage: `linear-gradient(136deg, ${color} 0%, ${color} 50%, ${color} 100%)`
    })
  })
);

function ColorlibStepIcon(props) {
  const {
    active,
    completed,
    className,
    index,
    label,
    questionData,
    invoiceProds,
    customNotes,
    openQuestion,
    transitionConfirmation
  } = props;

  let Icon = {
    "Confirmation Email Review": "E",
    "Delivery Ticket?": "DT",
    "Supplier Receipt": "SR",
    "COI?": "C/B",
    "Estimated Start Date": "SD",
    "Date NOTES": "DN",
    MAGICPLAN: "PD",
    MATTERPORT: "MT",
    "Transition Confirmation Needed?": "TA",
    "SF CONFIRMATION": "RA",
    "Is there furniture in the space?": "FA",
    Invoice: "INV",
    "Custome Notes": "IN",
    "Open Questions Needed?": "OQ",
    "Operation Project Review": "OPR"
  };
  let color = redWithOP;
  if (label?.type === "DROPDOWN") {
    let currentQues = questionData?.findIndex(
      (ques) => ques.question === label?.question
    );
    if (currentQues !== -1) {
      color = getColorForButton(questionData[currentQues][label?.key]);
      console.log("currentQues**", label, color);
      // if (color === "white") {
      //   color = redWithOP;
      // } else {
      //   color = redWithOP;
      // }
    } else {
      color = redWithOP;
    }
  }

  if (index === 2) {
    color = getColorDeliveryTicketBtn(questionData);
  } else if (index === 3) {
    color = getColorCOIBtn(questionData);
  } else if (index === 4) {
    color = getColorEstDateBtn(questionData);
  } else if (index === 5) {
    color = getColorProjectDataBtn(questionData);
  } else if (index === 6) {
    const validStatuses = [
      "Invoice : Reviewed and Approved",
      "Ops : Reviewed and Approved"
    ];
    let currentQues = questionData?.findIndex(
      (ques) => ques.question === label?.question
    );
    if (currentQues !== -1) {
      const allReviewed = transitionConfirmation.every((prod) =>
        validStatuses.includes(prod.status)
      );

      let value = questionData[currentQues][label?.key];
      if (value === "Not Needed") {
        color = defaultGray;
      } else {
        if (transitionConfirmation?.length == 0) {
          color = redWithOP;
        } else {
          color = allReviewed ? greenWithOP : redWithOP;
        }
      }
    } else {
      color = redWithOP;
    }

    console.log("currentQues--", currentQues, color);
  } else if (index === 7) {
    color = getColorSFRoomBtn(questionData);
  } else if (index === 8) {
    let currentQues = questionData?.findIndex(
      (ques) => ques.question === label?.question
    );
    if (currentQues !== -1) {
      let value = questionData[currentQues][label?.key];
      if (value === "No") {
        color = greenWithOP;
      } else if (value === "Not Yet Reviewed") {
        color = yellowWithOP;
      } else if (
        value === "Yes" &&
        questionData?.[currentQues]?.details?.status === "Customer Moving"
      ) {
        color = greenWithOP;
      } else if (
        value === "Yes" &&
        questionData?.[currentQues]?.details?.status === "FlooredAtHome Moving"
      ) {
        if (
          questionData?.[currentQues]?.details?.itemReviwed &&
          questionData?.[currentQues]?.details?.PlanReviewed
        ) {
          color = greenWithOP;
        }
      }
    } else {
      color = redWithOP;
    }
  } else if (index === 9) {
    const allReviewed = invoiceProds.every(
      (prod) => prod.status === "Yes. Reviewed. Good to go."
    );
    const red = invoiceProds.some(
      (prod) => prod.status === "Yes. Reviewed w/ Open Questions"
    );
    if (invoiceProds?.length == 0) {
      color = defaultGray;
    } else {
      color = allReviewed ? greenWithOP : red ? redWithOP : yellowWithOP;
    }
  } else if (index === 10) {
    const allReviewed = customNotes.every(
      (prod) => prod.status === "Yes. Reviewed. Good to go."
    );

    const red = customNotes.some(
      (prod) => prod.status === "Yes. Reviewed w/ Open Questions"
    );
    if (customNotes?.length == 0) {
      color = defaultGray;
    } else {
      color = allReviewed ? greenWithOP : red ? redWithOP : yellowWithOP;
    }
  } else if (index === 11) {
    let currentQues = questionData?.findIndex(
      (ques) => ques.question === label?.question
    );
    if (currentQues !== -1) {
      const allReviewed = openQuestion.every((prod) => prod.status === "DONE");

      let value = questionData[currentQues][label?.key];
      if (value === "Not Needed") {
        color = defaultGray;
      } else {
        if (openQuestion?.length == 0) {
          color = redWithOP;
        } else {
          color = allReviewed ? greenWithOP : redWithOP;
        }
      }
    } else {
      color = redWithOP;
    }
  } else if (index === 12) {
    color = "white";
  }
  console.log("label", label, color);

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      color={color}
      index={index}
      textColor={index === 12 ? "black" : "white"}
    >
      {index === 0 ? <HomeIcon /> : `${Icon[label?.question]}`}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  index: PropTypes.number
};

const StepperForm = ({
  data,
  id,
  setData,
  questionData,
  customerID,
  invoiceProds,
  customNotes,
  setInvoiceProds,
  setCustomNotes,
  openQuestion,
  setOpenQuestion,
  transitionConfirmation,
  setTransitionConsirmation,
  zc_po_id,
  setQuestionData
}) => {
  console.log("questionData", transitionConfirmation);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [type, setType] = React.useState("");
  const [pdfUploading, setPdfUploading] = React.useState(false);
  const [imgUploading, setImagUploading] = React.useState(false);
  const [questData, setQuestData] = React.useState(questionData || []);
  const navigate = useNavigate();
  console.log("customNotes", questData);

  const [isLoading, setIsLoading] = React.useState(false);

  const openQuestionsRef = React.useRef();
  const invoiceRef = React.useRef();
  const furnitureAuditRef = React.useRef();
  const customeNoteRef = React.useRef();
  const transitionRef = React.useRef();
  const OparationProjectReview = React.useRef();

  const handleImageChange = async (selectedFiles, mainQuestion, type, key) => {
    if (!selectedFiles) {
      return;
    }
    setIsLoading(true);
    if (type === "PDF") {
      setPdfUploading(true);
    } else if (type === "IMAGE") {
      setImagUploading(true);
    }

    let formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("originalname", selectedFiles[0]?.name);

    let imageUploaded = await uploadImage(formData);
    if (imageUploaded.data.status) {
      if (type === "IMAGE") {
        setQuestData((prevData) =>
          prevData.map((item) =>
            item.question === mainQuestion
              ? {
                  ...item,
                  images: [...item.images, imageUploaded?.data?.entity]
                }
              : item
          )
        );
        setImagUploading(false);
        setIsLoading(false);
      } else if (type === "PDF") {
        const keyIsLikePath = key.includes(".");

        let keyToConsider = "";
        if (keyIsLikePath) {
          const splitted = key.split(".");
          keyToConsider = splitted[splitted.length - 1];
        }

        setQuestData((prevData) =>
          prevData.map((item) => {
            if (item.question === mainQuestion) {
              if (keyIsLikePath) {
                return {
                  ...item,
                  details: {
                    ...item?.details,
                    [keyToConsider]: item?.details?.[keyToConsider]
                      ? [
                          ...item?.details[keyToConsider],
                          imageUploaded?.data?.entity
                        ]
                      : [imageUploaded?.data?.entity]
                  }
                };
              }
              return {
                ...item,
                doc_urls: [...item.doc_urls, imageUploaded?.data?.entity]
              };
            } else {
              return item;
            }
          })
        );
        setPdfUploading(false);
        setIsLoading(false);
      }
    }
  };
  const handleRemoveFiles = async (file, mainQuestion, type, key) => {
    // Find the current quest data based on the question
    let currentQuestData = questData.find((q) => q.question === mainQuestion);

    // Ensure currentQuestData and the specific key exist
    if (currentQuestData) {
      // Filter out the file to be removed
      let updatedFiles = [];
      if (key.includes(".")) {
        let keyValue = key.split(".");
        updatedFiles = currentQuestData[keyValue[0]][keyValue[1]].filter(
          (item) => item !== file
        );
        currentQuestData[keyValue[0]][keyValue[1]] = updatedFiles;
      } else {
        updatedFiles = currentQuestData[key].filter((item) => item !== file);
        currentQuestData[key] = updatedFiles;
      }

      // Update currentQuestData with the new files array

      // Make a copy of questData with the updated quest data
      const updatedQuestData = questData.map((item) =>
        item.question === mainQuestion ? currentQuestData : item
      );

      try {
        setIsLoading(true);
        // Call the API to update the quest data
        const response = await updateQuestion(customerID, currentQuestData);

        if (response?.data?.status) {
          // If the API call is successful, update the quest data state
          setQuestData(updatedQuestData);
          setIsLoading(false);
        } else {
          // Handle the case where the API call was not successful
          console.error(
            "API call failed:",
            response?.data?.message || "Unknown error"
          );
          setIsLoading(false);
        }
      } catch (error) {
        // Handle any errors during the API call
        console.error("Error occurred during API call:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getValueByKey = (obj, key) => {
    return key.split(".").reduce((o, k) => (o || {})[k], obj);
  };

  const handleFieldChange = (key, value, mainQuestion) => {
    console.log("value--", key, value, mainQuestion);

    setQuestData((prevData) => {
      const existingItem = prevData.find(
        (item) => item.question === mainQuestion
      );

      const keyIsLikePath = key.includes(".");

      let keyToConsider = "";
      if (keyIsLikePath) {
        const splitted = key.split(".");
        keyToConsider = splitted[splitted.length - 1];
      }

      let newData = [...prevData];

      if (existingItem) {
        newData = newData.map((item) => {
          if (item.question === mainQuestion) {
            if (keyIsLikePath) {
              return {
                ...item,
                details: { ...item.details, [keyToConsider]: value }
              };
            }
            return { ...item, [key]: value };
          }
          return item;
        });
      } else {
        const newQuestion = QuestionFormField.flat()
          .flat()
          .find((q) => q.question === mainQuestion);

        if (newQuestion) {
          if (keyIsLikePath) {
            newData.push({
              customerId: customerID,
              question: mainQuestion,
              status: null,
              note: "",
              doc_urls: [],
              images: [],
              details: { [keyToConsider]: value }
            });
          } else {
            newData.push({
              customerId: customerID,
              question: mainQuestion,
              status: key === "status" ? value : null,
              note: key === "note" ? value : "",
              doc_urls: key === "doc_urls" ? value : [],
              images: key === "images" ? value : []
            });
          }
        }
      }

      // Additional logic for updating "Suppliers" question based on "Delivery Ticket" status
      if (
        mainQuestion === "Delivery Ticket?" &&
        key === "status" &&
        value === "Not Needed"
      ) {
        const existingSuppliersItem = newData.find(
          (item) => item.question === "Supplier Receipt"
        );

        if (existingSuppliersItem) {
          newData = newData.map((item) => {
            if (item.question === "Supplier Receipt") {
              return { ...item, status: null };
            }
            return item;
          });
        }
        // else {
        //   const newSuppliersQuestion = QuestionFormField.flat()
        //     .flat()
        //     .find((q) => q.question === "Supplier Receipt");

        //   if (newSuppliersQuestion) {
        //     newData.push({
        //       ...newSuppliersQuestion,
        //       customerId: customerID,
        //       question: "Supplier Receipt",
        //       status: null,
        //       note: "",
        //       doc_urls: [],
        //       images: []
        //     });
        //   }
        // }
      }

      return newData;
    });
  };

  console.log("questData***", questData);

  console.log(questData, "questData");
  const totalSteps = () => {
    return QuestionFormField.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const updateProjectBacklogStatus = React.useCallback(
    (activeStep) => {
      let deliveryStatusToConsider = null;

      if (activeStep === 2) {
        deliveryStatusToConsider = getDeliveryTicketStatus(questData);
      }

      let backlogbody = {};
      if (deliveryStatusToConsider) {
        backlogbody.delivery_status = deliveryStatusToConsider;
      }

      if (_.size(backlogbody) > 0) {
        updateProjectBacklogStatusApi(zc_po_id, backlogbody);
      }
    },
    [questData]
  );

  const handleNext = async () => {
    if ((activeStep > 0 && activeStep < 6) || activeStep === 7) {
      setIsLoading(true);

      const handleApiCall = async (findQues) => {
        let findeQuestionIndex = questData?.findIndex(
          (ques) => ques?.question === findQues
        );

        if (findeQuestionIndex !== -1) {
          console.log(questData, "questData?.[findeQuestionIndex]");

          const response = await updateQuestion(
            customerID,
            questData?.[findeQuestionIndex]
          );
          if (response?.data?.status) {
            // Update questData with the new id if it's missing
            const updatedQuestData = questData.map((item, index) =>
              item?.question === findQues
                ? { ...item, id: item.id || response.data.entity.id }
                : item
            );

            setQuestData(updatedQuestData);
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      };

      if (
        activeStep === 5 ||
        activeStep === 2 ||
        activeStep === 7 ||
        activeStep === 3
      ) {
        const firstQuestion = QuestionFormField[activeStep][0][0]?.question;
        const secondQuestion = QuestionFormField[activeStep][1][0]?.question;

        await handleApiCall(secondQuestion);
        await handleApiCall(firstQuestion);
        let findeQuestionIndex1 = questData?.findIndex(
          (ques) => ques?.question === firstQuestion
        );
        let findeQuestionIndex2 = questData?.findIndex(
          (ques) => ques?.question === secondQuestion
        );

        const Q1Exists = findeQuestionIndex1 !== -1;
        const Q2Exists = findeQuestionIndex2 !== -1;

        if (Q1Exists || Q2Exists) {
          const Q1 = questData?.[findeQuestionIndex1];
          const Q2 = questData?.[findeQuestionIndex2];
          if (activeStep === 7) {
            let status = "Not Yet Reviewed";
            if (
              Q1?.status == "Not Yet Reviewed" ||
              Q2?.status == "Not Yet Reviewed"
            ) {
              status = "Not Yet Reviewed";
            } else if (
              Q1?.status == "Reviewed w/ Open Questions" ||
              Q2?.status == "Reviewed w/ Open Questions"
            ) {
              status = "Reviewed w/ Open Questions";
            } else if (
              Q1?.status == "Reviewed and Approved" &&
              Q2?.status == "Reviewed and Approved"
            ) {
              status = "Reviewed and Approved";
            }

            let response = await updateQuestion(customerID, {
              question: "SF_OVERALL_STATUS",
              status
            });
          } else if (activeStep === 3) {
            let status = "Not Needed";
            if (
              Q1?.status === "Yes. Not Yet Started" ||
              Q2?.status === "Yes. Not Yet Started"
            ) {
              status = "Yes. Not Yet Started";
            } else if (
              Q1?.status === "Yes. In Process" ||
              Q2?.status === "Yes. In Process"
            ) {
              status = "Yes. In Process";
            } else if (
              Q1?.status === "Yes. Complete" &&
              Q2?.status === "Yes. Complete"
            ) {
              status = "Yes. Complete";
            } else if (
              Q1?.status === "Yes. Complete" ||
              Q2?.status === "Yes. Complete"
            ) {
              status = "Yes. Complete";
            }

            let response = await updateQuestion(customerID, {
              question: "COI_OVERALL_STATUS",
              status
            });
          }
        }
      } else {
        const findQues = QuestionFormField[activeStep][0][0]?.question;
        handleApiCall(findQues);
      }

      if (activeStep === 2) {
        updateProjectBacklogStatus(activeStep);
      }
    } else if (activeStep === 6) {
      const handleApiCall = async (findQues) => {
        let findeQuestionIndex = questData?.findIndex(
          (ques) => ques?.question === findQues
        );

        if (findeQuestionIndex !== -1) {
          const response = await updateQuestion(
            customerID,
            questData?.[findeQuestionIndex]
          );
          if (response?.data?.status) {
            // Update questData with the new id if it's missing
            const updatedQuestData = questData.map((item, index) =>
              item?.question === findQues
                ? { ...item, id: item.id || response.data.entity.id }
                : item
            );

            setQuestData(updatedQuestData);
            setIsLoading(false);
          }
        }
      };

      const findQues = QuestionFormField[activeStep][0][0]?.question;
      handleApiCall(findQues);
      if (transitionRef.current) {
        transitionRef.current.performAction();
      }
      setIsLoading(false);
    } else if (activeStep === 8) {
      if (furnitureAuditRef.current) {
        furnitureAuditRef.current.performAction();
      }

      setIsLoading(false);
    } else if (activeStep === 9) {
      if (invoiceRef.current) {
        invoiceRef.current.performAction();
      }

      setIsLoading(false);
    } else if (activeStep === 10) {
      if (customeNoteRef.current) {
        customeNoteRef.current.performAction();
      }

      setIsLoading(false);
    } else if (activeStep === 11) {
      const handleApiCall = async (findQues) => {
        let findeQuestionIndex = questData?.findIndex(
          (ques) => ques?.question === findQues
        );

        if (findeQuestionIndex !== -1) {
          const response = await updateQuestion(
            customerID,
            questData?.[findeQuestionIndex]
          );
          if (response?.data?.status) {
            // Update questData with the new id if it's missing
            const updatedQuestData = questData.map((item, index) =>
              item?.question === findQues
                ? { ...item, id: item.id || response.data.entity.id }
                : item
            );

            setQuestData(updatedQuestData);
            setIsLoading(false);
          }
        }
      };

      const findQues = QuestionFormField[activeStep][0][0]?.question;
      handleApiCall(findQues);
      if (openQuestionsRef.current) {
        openQuestionsRef.current.performAction();
      }

      setIsLoading(false);
    } else if (activeStep === 12) {
      if (OparationProjectReview.current) {
        OparationProjectReview.current.performAction();
      }

      setIsLoading(false);
    } else {
      const newActiveStep =
        isLastStep() && !allStepsCompleted()
          ? QuestionFormField.findIndex((step, i) => !(i in completed))
          : activeStep + 1;
      setActiveStep(newActiveStep);
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
    }
    setIsLoading(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const newCompleted = completed;
    newCompleted[activeStep] = false;
    setCompleted(newCompleted);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const fieldsToRender = React.useCallback(
    (queObj, mainQuest) => {
      const mainQuestion = mainQuest;
      const currentQuestData =
        questData.find((q) => q.question === mainQuestion) || {};
      const valueKey = queObj.key;
      const value = currentQuestData[valueKey] || "";
      console.log("currentQuestData", currentQuestData);

      switch (queObj.type) {
        case "BOOLEAN":
          return (
            <BooleanField
              question={queObj.question}
              value={value}
              handleClickBoolean={(newValue) =>
                handleFieldChange(valueKey, newValue, mainQuestion)
              }
            />
          );
        case "TEXT":
          return (
            <NoteField
              question={queObj.question}
              // value={value || ""}
              value={
                queObj.key.includes(".")
                  ? getValueByKey(currentQuestData, queObj.key)
                  : decode(value)
              }
              handleNoteBlur={(event) =>
                handleFieldChange(valueKey, event.target.value, mainQuestion)
              }
              id={queObj.key}
              name={queObj.key}
              type={queObj.fieldType || "text"}
              multiline={queObj?.isMultiLine}
            />
          );
        case "DATE":
          console.log("Data", value);
          return (
            <CustomDatePicker
              question={queObj.question}
              value={value || ""}
              handleNoteBlur={(date) =>
                handleFieldChange(
                  valueKey,
                  moment(date.$d).format("MM/DD/YYYY"),
                  mainQuestion
                )
              }
              id="date-picker"
              name={queObj.key}
              type={queObj.fieldType || "text"}
            />
          );
        case "DROPDOWN":
          return (
            <DropdownField
              question={
                queObj.question === "SF CONFIRMATION"
                  ? "Review of overall project size"
                  : queObj.question
              }
              value={
                queObj.key.includes(".")
                  ? getValueByKey(currentQuestData, queObj.key)
                  : value
              }
              options={queObj.option}
              handleChangeDropdown={(event) =>
                handleFieldChange(valueKey, event.target.value, mainQuestion)
              }
              selectedBackgroundColor="red"
            />
          );
        case "IMAGE":
          return (
            <ImageUploadField
              label={queObj?.question}
              handleImageChange={(selectedFiles) =>
                handleImageChange(
                  selectedFiles,
                  mainQuestion,
                  queObj.type,
                  queObj.key
                )
              }
              name="imageUpload"
              id="imageUpload"
              files={currentQuestData.images || []}
              accept="image/*"
              type={queObj.type}
              imgUploading={imgUploading}
              handleRemoveFiles={(file) =>
                handleRemoveFiles(file, mainQuestion, queObj.type, queObj.key)
              }
            />
          );
        case "PDF":
          return (
            <ImageUploadField
              label={queObj?.question}
              handleImageChange={(selectedFiles) =>
                handleImageChange(
                  selectedFiles,
                  mainQuestion,
                  queObj.type,
                  queObj.key
                )
              }
              name="pdfUpload"
              id="pdfUpload"
              // files={currentQuestData.doc_urls || []}
              files={
                queObj.key.includes(".")
                  ? getValueByKey(currentQuestData, queObj.key) || []
                  : currentQuestData.doc_urls || []
              }
              accept={
                queObj.canUploadMixFiles
                  ? "application/pdf, image/*"
                  : "application/pdf"
              }
              type={queObj.type}
              pdfUploading={pdfUploading}
              handleRemoveFiles={(file) =>
                handleRemoveFiles(file, mainQuestion, queObj.type, queObj.key)
              }
            />
          );
        case "CHECKBOX":
          return (
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
              <MuiCheckbox
                value={
                  queObj.key.includes(".")
                    ? getValueByKey(currentQuestData, queObj.key)
                    : value
                }
                name={queObj.key}
                onChange={(event) =>
                  handleFieldChange(
                    valueKey,
                    event.target.checked,
                    mainQuestion
                  )
                }
                label={queObj.question}
              />
            </Box>
          );
        default:
          return null;
      }
    },
    [activeStep, questData]
  );

  console.log("questionDADATA", questData);

  const memoizedCustomerInfo = React.useMemo(() => {
    return (
      <>
        {" "}
        {activeStep === 6 ? (
          <>
            <TransitionConfirmation
              ref={transitionRef}
              customerID={customerID}
              transitionConfirmation={transitionConfirmation}
              setTransitionConsirmation={setTransitionConsirmation}
              setIsLoading={setIsLoading}
              fieldsToRender={fieldsToRender}
              questData={questData}
              setQuestData={setQuestData}
            />
          </>
        ) : activeStep === 8 ? (
          <>
            <FurnitureAudit
              ref={furnitureAuditRef}
              customerID={customerID}
              transitionConfirmation={transitionConfirmation}
              setTransitionConsirmation={setTransitionConsirmation}
              setIsLoading={setIsLoading}
              fieldsToRender={fieldsToRender}
              questData={questData}
              setQuestData={setQuestData}
            />
          </>
        ) : activeStep === 9 ? (
          <>
            <InvoiceTable
              ref={invoiceRef}
              invoiceProds={invoiceProds || []}
              setInvoiceProds={setInvoiceProds}
              customerID={customerID}
              customNotes={customNotes}
              setIsLoading={setIsLoading}
            />
          </>
        ) : activeStep === 10 ? (
          <CustomNoteTable
            ref={customeNoteRef}
            setCustomNotes={setCustomNotes}
            customerID={customerID}
            customNotes={customNotes || []}
            setIsLoading={setIsLoading}
          />
        ) : activeStep === 11 ? (
          <OpenQuestions
            ref={openQuestionsRef}
            customerID={customerID}
            openQuestion={openQuestion}
            setOpenQuestion={setOpenQuestion}
            setIsLoading={setIsLoading}
            fieldsToRender={fieldsToRender}
            questData={questData}
          />
        ) : activeStep === 12 ? (
          <OPR
            ref={OparationProjectReview}
            customerID={customerID}
            openQuestion={openQuestion}
            setOpenQuestion={setOpenQuestion}
            setIsLoading={setIsLoading}
          />
        ) : (
          <QuestionForm
            fieldsToRender={fieldsToRender}
            QuestionFormField={QuestionFormField[activeStep]}
            activeStep={activeStep}
            questData={questData}
          />
        )}
      </>
    );
  }, [
    fieldsToRender,
    activeStep,
    questData,
    QuestionFormField,
    openQuestion,
    customNotes,
    invoiceProds,
    setOpenQuestion,
    transitionConfirmation,
    setTransitionConsirmation
  ]);

  const homePage = () => {
    navigate(`/ek1ThPbdwtcAQMtg71pMeCl1XRdrs85H/home`);
  };
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100vh",
        position: "relative"
      }}
    >
      <MuiSnackbar
        open={open}
        message={message || ""}
        type={type || ""}
        onClose={() => setOpen(false)}
      />
      <Button
        variant="contained"
        onClick={homePage}
        sx={{
          position: "absolute",
          top: "4px",
          left: "-8px",
          zIndex: 1,
          backgroundColor: "#1e2e5a",
          fontSize: "12px",
          padding: "45px 14px",
          width: "auto",
          height: "45px",
          minWidth: "fit-content",
          margin: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "4px"
        }}
      >
        <ArrowBackIosIcon sx={{ fontSize: 22 }} />
      </Button>
      <Box sx={{ width: "100%", pt: 1, px: 5 }}>
        <div>
          <Stepper
            alternativeLabel
            nonLinear
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            sx={{ margin: "10px" }}
          >
            {QuestionFormField.map((label, index) => (
              <Step
                key={
                  index === 0 || index === QuestionFormField.length - 1
                    ? "Home"
                    : label[0][0].question
                }
                index={index}
              >
                <StepButton
                  type="button"
                  color="inherit"
                  onClick={handleStep(index)}
                  sx={{ width: "80px" }}
                >
                  <StepLabel
                    StepIconComponent={(props) => (
                      <ColorlibStepIcon
                        {...props}
                        index={index}
                        label={label[0][0]}
                        questionData={questData}
                        invoiceProds={invoiceProds}
                        customNotes={customNotes}
                        openQuestion={openQuestion}
                        transitionConfirmation={transitionConfirmation}
                      />
                    )}
                  />
                </StepButton>
                <div
                  style={{
                    fontWeight: "bold",
                    marginTop: "6px",
                    fontSize: "11px",
                    color: activeStep === index ? "#3e4770" : "black"
                  }}
                >
                  {Icon[label?.[0]?.[0]?.question]}
                </div>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <Divider sx={{ my: "12px" }} />
        </div>
        <div>
          <React.Fragment>
            <form>
              <div className="formContainer">
                {activeStep === 0 && (
                  <Home
                    data={data?.po_data}
                    questionData={questData}
                    openQuestion={openQuestion}
                    customNotes={customNotes}
                    invoiceProds={invoiceProds}
                    transitionConfirmation={transitionConfirmation}
                  />
                )}
                {activeStep > 0 && memoizedCustomerInfo}
              </div>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  pt: 2
                }}
              >
                {/* <Button
                  type="button"
                  variant="contained"
                  color="inherit"
                  disabled={isLoading || activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Previous
                </Button> */}
                <Button
                  type="button"
                  variant="contained"
                  color={isLoading ? "inherit" : "primary"}
                  disabled={isLoading}
                  onClick={handleNext}
                  sx={{
                    display: "flex", // Use flexbox to align items
                    alignItems: "center", // Align items vertically
                    gap: 1 // Space between icon and text
                  }}
                >
                  {isLoading && <CircularProgress color="inherit" size={15} />}
                  {activeStep === 0 ? " Next" : "Save"}
                </Button>
              </Box>
            </form>
          </React.Fragment>
        </div>
      </Box>
    </Card>
  );
};

export default StepperForm;
