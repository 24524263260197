import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Divider,
  DialogTitle,
  IconButton,
  DialogContent,
  Dialog,
  DialogContentText
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { QuestionFormField } from "../FormBuilder/Questions";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import {
  defaultGray,
  getColor,
  getColorCOIBtn,
  getColorDeliveryTicketBtn,
  getColorEstDateBtn,
  getColorFurnitureAudit,
  getColorProjectDataBtn,
  getColorSFRoomBtn,
  getReviewStatusColor,
  greenColor,
  greenOP,
  greenWithOP,
  redColor,
  redOP,
  redWithOP,
  yellowOP,
  yellowWithOP
} from "../utils/customeColor";
import Slider from "react-slick";
import GetIconFile from "../../assets/GetIconFile";
import { decode } from "html-entities";
import { CloseFullscreen } from "@mui/icons-material";
let Icon = {
  "Confirmation Email Review": "Confirmation Email Review",
  "Delivery Ticket?": "Delivery Ticket",
  "Supplier Receipt": "Supplier Receipt",
  "COI?": "COI/Building Approval",
  "Estimated Start Date": "Estimated Start Date",
  "Date NOTES": "Date Notes",
  MAGICPLAN: "Project Data",
  MATTERPORT: "Matterport",
  "Transition Confirmation Needed?": "Transition Confirmation",
  "SF CONFIRMATION": "Room Audit",
  "Is there furniture in the space?": "Furniture Audit",
  Invoice: "Invoice",
  "Custome Notes": "Custom Notes",
  "Open Questions Needed?": "Open Question"
};

const Home = ({
  data,
  questionData,
  openQuestion,
  customNotes,
  invoiceProds,
  transitionConfirmation
}) => {
  const [openPdf, setOpenPdf] = useState(false);
  const [currentPdf, setCurrentPdf] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const customDialogContentStyle = {
    height: "610px",
    width: "800px"
  };
  const [images, setImages] = useState([]);
  const customDotStyles = `
    .image-slider-popup .slick-dots {
      bottom: -22px;
    }
    .image-slider-popup .slick-dots li button:before {
      font-size: 16px !important;
    }
    .image-slider-popup .slick-slide {
        height: 590px;
    }
`;

  const handleFileClick = (fileUrl, index, files) => {
    setImages(files);
    setCurrentFileIndex(index);
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentFileIndex(0);
  };
  const settings = {
    initialSlide: currentFileIndex,
    arrows: false,
    infinite: false,
    dots: true
  };
  const QuestionAnswerPair = ({ question, answer, style, type = "TEXT" }) => {
    return (
      <Grid container className="parentGridStyle" sx={{ mb: 1 }}>
        <Grid item xs={6} className="quesGridStyle">
          <Typography variant="body2" className="typoStyle">
            {question}
          </Typography>
        </Grid>
        <Grid item xs={0.01}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5.95} className={`ansGridStyle`}>
          <Typography variant="body2" className="ansTypoStyle" sx={style}>
            {type === "DATE" ? (
              moment(answer).format("ll")
            ) : type === "DATETIME" ? (
              moment(answer).format("LLL")
            ) : type == "HTML" ? (
              <span dangerouslySetInnerHTML={{ __html: answer }} />
            ) : (
              answer
            )}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const ImagePreview = ({ files, handleClick, question }) => {
    return (
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginY: "4px", textAlign: "left" }}
        >
          {question}:
        </Typography>
        {files?.map((file, index) => (
          <Box
            component="img"
            src={file}
            onClick={() => handleClick(file, index, files)}
            alt={`Image Preview`}
            sx={{
              minWidth: "50px",
              height: "45px",
              objectFit: "cover",
              borderRadius: "4px",
              marginY: "4px",
              padding: "6px",
              transition: "transform 0.1s ease-out, box-shadow 0.1s ease-out", // Smooth transition for scaling and shadow
              "&:hover": {
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" // Enhanced shadow on hover
              }
            }}
            key={index}
          />
        ))}
      </Box>
    );
  };

  const PdfPreview = ({ files, handleClick, question }) => {
    console.log(files, "filesss", question);
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginY: "4px", textAlign: "left" }}
        >
          {question}:
        </Typography>
        <Box sx={{ display: "flex", maxWidth: "35vw", overflow: "auto" }}>
          {files?.map((file, index) => {
            console.log(file, "file");
            let filterImgaes = files?.filter(
              (image) => !image?.match(/\.pdf$/i)
            );
            console.log("filterImgaes", filterImgaes);
            return file.match(/\.pdf$/i) ? (
              <Box
                component="img"
                src="/pdf-icon.svg"
                onClick={() => handleClick(file)}
                sx={{
                  minWidth: "40px",
                  height: "45px",
                  objectFit: "cover",
                  borderRadius: "4px",
                  marginY: "4px",
                  padding: "6px",
                  transition:
                    "transform 0.1s ease-out, box-shadow 0.1s ease-out", // Smooth transition for scaling and shadow
                  "&:hover": {
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" // Enhanced shadow on hover
                  }
                }}
                key={index}
              />
            ) : (
              <Box
                component="img"
                src={file}
                onClick={() =>
                  handleFileClick(
                    file,
                    filterImgaes?.indexOf(file),
                    filterImgaes
                  )
                }
                alt={`Image Preview`}
                sx={{
                  minWidth: "50px",
                  height: "45px",
                  objectFit: "cover",
                  borderRadius: "4px",
                  marginY: "4px",
                  padding: "4px",
                  transition:
                    "transform 0.1s ease-out, box-shadow 0.1s ease-out", // Smooth transition for scaling and shadow
                  "&:hover": {
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" // Enhanced shadow on hover
                  }
                }}
                key={index}
              />
            );
          })}
        </Box>
      </Box>
    );
  };

  const DatePreview = ({ question, date }) => {
    return (
      <Box
        sx={{ marginY: "4px", display: "flex", alignItems: "center", gap: 1 }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold", flexShrink: 0 }}>
          {question}:
        </Typography>
        <Typography variant="body2">
          {date ? moment(date).format("ll") : ""}
        </Typography>
      </Box>
    );
  };

  const DefaultPreview = ({ question, content }) => {
    console.log(question, content, "content");
    return (
      <Box
        sx={{ marginY: "4px", display: "flex", alignItems: "center", gap: 1 }}
      >
        <Typography variant="body2" sx={{ fontWeight: "bold", flexShrink: 0 }}>
          {question}:
        </Typography>
        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {/* {content} */}
          <span dangerouslySetInnerHTML={{ __html: decode(content || "") }} />
        </Typography>
      </Box>
    );
  };

  const filteredArray = QuestionFormField.slice(1, -1);
  console.log("data1", filteredArray);

  const handlePdfClick = (file) => {
    setCurrentPdf(file);
    setOpenPdf(true);
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
    setCurrentPdf(null);
  };
  const getValueByKey = (obj, key) => {
    return key.split(".").reduce((o, k) => (o || {})[k], obj);
  };

  return (
    <Card sx={{ width: "88vw", maxWidth: "100%", m: 2 }}>
      <CardContent sx={{ height: "100%" }}>
        <Box sx={{ flexGrow: 1, height: "100%" }}>
          <Grid container spacing={2} sx={{ height: "100%" }}>
            <Grid xs={12} md={6}>
              <Box sx={{ p: 1 }}>
                <Box sx={{ height: "62vh", overflowY: "auto" }}>
                  <Box sx={{ pt: 1 }}>
                    <QuestionAnswerPair
                      question="Customer Name"
                      answer={data?.Deal_Name || ""}
                    />

                    <QuestionAnswerPair
                      question="Status"
                      answer={data?.Stage || ""}
                    />

                    <QuestionAnswerPair
                      question="Owner"
                      answer={data?.Owner?.name || ""}
                    />

                    <QuestionAnswerPair
                      question="Job Type"
                      answer={data?.Job_Type || ""}
                    />

                    <QuestionAnswerPair
                      question="Property Type"
                      answer={data?.Property_Type || ""}
                    />
                    {data?.Doorman_building === "Yes" ? (
                      <QuestionAnswerPair
                        question="Doorman_building ?"
                        answer={data?.Doorman_building || ""}
                      />
                    ) : (
                      ""
                    )}
                    {data?.Metal_doors_to_be_scribed_around ? (
                      <QuestionAnswerPair
                        question="Metal doors to be scribed around?"
                        answer={"Yes" || ""}
                      />
                    ) : (
                      ""
                    )}

                    {data?.Special_Instructions_For_Getting_Into_Home_Notes !==
                      "" && (
                      <>
                        <QuestionAnswerPair
                          question="Special Instructions For Getting Into Home Notes"
                          answer={
                            data?.Special_Instructions_For_Getting_Into_Home_Notes ||
                            ""
                          }
                        />
                      </>
                    )}

                    {/* //Confirmation that FlooredAtHome will be the only trade New */}

                    {data?.Confirmation_that_FAH_will_be_the_only_trade &&
                    data?.Confirmation_that_FAH_will_be_the_only_trade ==
                      "No" ? (
                      <QuestionAnswerPair
                        question="Confirmation that FlooredAtHome will be the only trade"
                        answer={
                          data?.Confirmation_that_FAH_will_be_the_only_trade ||
                          ""
                        }
                      />
                    ) : (
                      ""
                    )}

                    {/* //Disposal Plan New */}
                    {data?.Disposal_Plan ? (
                      <QuestionAnswerPair
                        question="Disposal Plan"
                        answer={data?.Disposal_Plan || ""}
                      />
                    ) : (
                      ""
                    )}

                    {/* //Walls & Baseboard to be painted after the project New  */}
                    {data?.Walls_Baseboard_to_be_painted_after_the_project &&
                    data?.Walls_Baseboard_to_be_painted_after_the_project ==
                      "No" ? (
                      <QuestionAnswerPair
                        question="Walls & Baseboard to be painted after the project"
                        answer={
                          data?.Walls_Baseboard_to_be_painted_after_the_project ||
                          ""
                        }
                      />
                    ) : (
                      ""
                    )}

                    <QuestionAnswerPair
                      question="City"
                      answer={data?.City || ""}
                    />

                    <QuestionAnswerPair
                      question="State"
                      answer={data?.State || ""}
                    />

                    <QuestionAnswerPair
                      question="Total Job Size"
                      answer={data?.Amount || ""}
                    />

                    <QuestionAnswerPair
                      question="Deposit Taken"
                      answer={data?.Deposit_Taken || ""}
                    />
                    <QuestionAnswerPair
                      question="Deposit Taken date"
                      answer={data?.Deposit_Taken_date || ""}
                      type="DATETIME"
                    />

                    <QuestionAnswerPair
                      question="Method Of Deposit"
                      answer={data?.Method_of_Deposit || ""}
                    />

                    <QuestionAnswerPair
                      question="Payment Terms"
                      answer={data?.Payment_Terms || ""}
                    />

                    <QuestionAnswerPair
                      question="Other Payment Notes"
                      answer={data?.Other_Payment_Notes || ""}
                    />

                    {data?.Delivery_Required === "Delivery Required" ? (
                      <QuestionAnswerPair
                        question="Delivery Required"
                        answer={data?.Delivery_Required || ""}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Delivery Required"
                        answer={data?.Delivery_Required || ""}
                      />
                    )}
                    {data?.Material_Confirmed ===
                    "Material Required: Not Yet Confirmed" ? (
                      <QuestionAnswerPair
                        question="Material Confirmed"
                        answer={data?.Material_Confirmed || ""}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Material Confirmed"
                        answer={data?.Material_Confirmed || ""}
                      />
                    )}
                    <QuestionAnswerPair
                      question="Requires COI"
                      answer={data?.Requires_COI || ""}
                    />
                    <QuestionAnswerPair
                      question="Estimated or Agreed Start Date"
                      answer={data?.Estimated_or_Agreed_Start_Date || ""}
                      type="DATE"
                    />

                    {data?.Confirmed_timing_details &&
                    (data?.Confirmed_timing_details ===
                      "Timing Confirmed with Ops" ||
                      data?.Confirmed_timing_details ===
                        "Start Date 100% Confirmed with OPS" ||
                      data?.Confirmed_timing_details ===
                        "Start Date 100% Confirmed with Sales") ? (
                      <QuestionAnswerPair
                        question=" Confirmed timing details"
                        answer={data?.Confirmed_timing_details || ""}
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Confirmed timing details"
                        answer={data?.Confirmed_timing_details || ""}
                      />
                    )}
                    <QuestionAnswerPair
                      question="Timing Requirements"
                      answer={data?.Timing_Requirements || ""}
                    />
                    <QuestionAnswerPair
                      question="Customer Plan During Project"
                      answer={data?.Customer_Plan_During_Project || ""}
                    />
                    {/* //Customer Plan For The Project Notes New */}
                    {data?.Customer_Plan_For_The_Project_Notes ? (
                      <QuestionAnswerPair
                        question="Customer Plan For The Project Notes"
                        answer={data?.Customer_Plan_For_The_Project_Notes || ""}
                      />
                    ) : (
                      ""
                    )}
                    {data?.Critical_Timing_Requirements &&
                    data?.Critical_Timing_Requirements === "None" ? (
                      <QuestionAnswerPair
                        question="Critical Timing Requirements"
                        answer={data?.Critical_Timing_Requirements || ""}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Critical Timing Requirements"
                        answer={data?.Critical_Timing_Requirements || ""}
                        style={{ color: "red" }}
                      />
                    )}

                    <QuestionAnswerPair
                      question="When does the floor need to be used"
                      answer={data?.When_does_the_floor_need_to_be_used || ""}
                      type="DATE"
                    />

                    <QuestionAnswerPair
                      question="Earliest date customer can start"
                      answer={data?.Earliest_date_customer_can_start || ""}
                      type="DATE"
                    />
                    <QuestionAnswerPair
                      question="Other Project Timing Notes"
                      answer={data?.Other_Project_Timing_Notes || ""}
                      type="HTML"
                    />

                    {data?.Any_project_complications_to_be_discussed_with_OPS &&
                    data?.Any_project_complications_to_be_discussed_with_OPS ===
                      "Yes" ? (
                      <QuestionAnswerPair
                        question="Any project complications to be discussed with OPS directly"
                        answer={
                          data?.Any_project_complications_to_be_discussed_with_OPS ||
                          ""
                        }
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Any project complications to be discussed with OPS directly"
                        answer={
                          data?.Any_project_complications_to_be_discussed_with_OPS ||
                          ""
                        }
                      />
                    )}

                    {data?.Complications_to_be_discussed && (
                      <QuestionAnswerPair
                        question="Complications to be discussed"
                        answer={data?.Complications_to_be_discussed || ""}
                      />
                    )}

                    {data?.Are_we_matching_any_existing_floor_Refi_orInst &&
                    data?.Are_we_matching_any_existing_floor_Refi_orInst ===
                      "Yes" ? (
                      <QuestionAnswerPair
                        question="Are we matching any existing floor (Refinishing or Install)"
                        answer={
                          data?.Are_we_matching_any_existing_floor_Refi_orInst ||
                          ""
                        }
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Are we matching any existing floor (Refinishing or Install)"
                        answer={
                          data?.Are_we_matching_any_existing_floor_Refi_orInst ||
                          ""
                        }
                      />
                    )}
                    {/* //Is any leveling needed New */}
                    {data?.Is_any_leveling_needed &&
                    data?.Is_any_leveling_needed == "Yes" ? (
                      <QuestionAnswerPair
                        question="Is any leveling needed"
                        answer={"Yes" || ""}
                      />
                    ) : (
                      ""
                    )}
                    {/* //Why Customer is Doing the Project New */}
                    {data?.Why_Customer_is_Doing_the_Project ? (
                      <QuestionAnswerPair
                        question="Why Customer is Doing the Project"
                        answer={data?.Why_Customer_is_Doing_the_Project || ""}
                      />
                    ) : (
                      ""
                    )}
                    {/* //Most Important thing to customer about project New*/}
                    {data?.Most_Important_thing_to_customer_about_project ? (
                      <QuestionAnswerPair
                        question="Most Important thing to customer about project"
                        answer={
                          data?.Most_Important_thing_to_customer_about_project ||
                          ""
                        }
                      />
                    ) : (
                      ""
                    )}

                    {data?.Are_we_matching_any_existing_floor_Refi_orInst &&
                    data?.Are_we_matching_any_existing_floor_Refi_orInst ===
                      "No" ? (
                      <QuestionAnswerPair
                        question="Confirmation for OPS to reach out to the customer"
                        answer={
                          data?.Confirmation_for_OPS_to_reach_out_to_the_customer ||
                          ""
                        }
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Confirmation for OPS to reach out to the customer"
                        answer={
                          data?.Confirmation_for_OPS_to_reach_out_to_the_customer ||
                          ""
                        }
                      />
                    )}

                    {data?.Is_there_any_furniture_to_be_moved_between_floors &&
                    data?.Is_there_any_furniture_to_be_moved_between_floors ===
                      "Yes" ? (
                      <QuestionAnswerPair
                        question="Is there any furniture to be moved between floors"
                        answer={
                          data?.Is_there_any_furniture_to_be_moved_between_floors ||
                          ""
                        }
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Is there any furniture to be moved between floors"
                        answer={
                          data?.Is_there_any_furniture_to_be_moved_between_floors ||
                          ""
                        }
                      />
                    )}

                    {data?.Any_pieces_that_will_require_more_than_2_people &&
                    data?.Any_pieces_that_will_require_more_than_2_people ===
                      "Yes" ? (
                      <QuestionAnswerPair
                        question="Any pieces that will require more than 2 people"
                        answer={
                          data?.Any_pieces_that_will_require_more_than_2_people ||
                          ""
                        }
                        style={{ color: "red" }}
                      />
                    ) : (
                      <QuestionAnswerPair
                        question="Any pieces that will require more than 2 people"
                        answer={
                          data?.Any_pieces_that_will_require_more_than_2_people ||
                          ""
                        }
                      />
                    )}
                    {/* //Other Notes New */}
                    <QuestionAnswerPair
                      question="Other Notes"
                      answer={data?.Other_Notes || ""}
                    />

                    {/* //Will the new floor be lower than the current floor New */}
                    {data?.Will_the_new_floor_be_lower_than_the_current_floor &&
                    data?.Will_the_new_floor_be_lower_than_the_current_floor ==
                      "Yes" ? (
                      <QuestionAnswerPair
                        question="Will the new floor be lower than the current floor"
                        answer={
                          data?.Will_the_new_floor_be_lower_than_the_current_floor ||
                          ""
                        }
                      />
                    ) : (
                      ""
                    )}

                    {/* //Doors jams expected to be cut? New */}
                    {data?.Doors_jams_expected_to_be_cut ? (
                      <QuestionAnswerPair
                        question="Doors jams expected to be cut?"
                        answer={data?.Doors_jams_expected_to_be_cut || ""}
                      />
                    ) : (
                      ""
                    )}
                    {/* //Doors expected to be cut? New */}
                    {data?.Doors_expected_to_be_cut ? (
                      <QuestionAnswerPair
                        question="Doors expected to be cut?"
                        answer={"Yes" || ""}
                      />
                    ) : (
                      ""
                    )}
                    {/* //Is Appliances In Scope & Appliances has value New */}
                    {data?.Is_Appliances_In_Scope &&
                    data?.Is_Appliances_In_Scope == "Yes" &&
                    data?.Appliances?.length > 0 ? (
                      <QuestionAnswerPair
                        question="Appliances"
                        answer={data?.Appliances || ""}
                      />
                    ) : (
                      ""
                    )}
                    {/* //Is Special Items In Scope & Special Items has value New */}
                    {data?.Is_Special_Items_In_Scope &&
                    data?.Is_Special_Items_In_Scope == "Yes" &&
                    data?.Special_Items?.length > 0 ? (
                      <QuestionAnswerPair
                        question="Special Items"
                        answer={data?.Special_Items || ""}
                      />
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  p: 2,
                  borderLeft: { md: "1px solid #ccc" },
                  height: "57vh",
                  overflowY: "auto"
                }}
              >
                {filteredArray?.map((quesArray, mainIndex) => {
                  let combinedArray = [];
                  if (
                    mainIndex === 1 ||
                    mainIndex === 2 ||
                    mainIndex === 4 ||
                    mainIndex === 6
                  ) {
                    combinedArray = quesArray.flat();
                  }
                  // Use combinedArray if mainIndex is 5, otherwise use quesArray
                  const arrayToRender =
                    mainIndex === 4 ||
                    mainIndex === 1 ||
                    mainIndex === 2 ||
                    mainIndex === 6
                      ? [combinedArray]
                      : quesArray;
                  return arrayToRender?.map((ques, index) => {
                    let mainQuestion = ques[0]?.question;
                    const currentQuestData =
                      questionData.find((q) => q.question === mainQuestion) ||
                      {};
                    let bgColor =
                      getColor(currentQuestData?.status) === "white"
                        ? redOP
                        : getColor(currentQuestData?.status);

                    if (mainIndex === 1) {
                      bgColor = getColorDeliveryTicketBtn(questionData, true);
                    }
                    if (mainIndex === 2) {
                      bgColor = getColorCOIBtn(questionData, true);
                    }
                    if (mainIndex === 3) {
                      bgColor = getColorEstDateBtn(questionData, true);
                    } else if (mainIndex === 4) {
                      bgColor = getColorProjectDataBtn(questionData, true);
                    } else if (mainIndex === 5) {
                      const validStatuses = [
                        "Invoice : Reviewed and Approved",
                        "Ops : Reviewed and Approved"
                      ];
                      let currentQues = questionData?.findIndex(
                        (QQues) => QQues.question === ques?.[0]?.question
                      );
                      if (currentQues !== -1) {
                        const allReviewed = transitionConfirmation.every(
                          (prod) => validStatuses.includes(prod.status)
                        );

                        let value = questionData[currentQues][ques?.[0]?.key];
                        if (transitionConfirmation?.length == 0) {
                          bgColor =
                            value === "Yes Needed"
                              ? redOP
                              : value === "Not Needed"
                              ? defaultGray
                              : redOP;
                        } else {
                          bgColor = allReviewed ? greenOP : redOP;
                        }
                      } else {
                        bgColor = redOP;
                      }
                      console.log(bgColor, "bg-color");
                    } else if (mainIndex === 6) {
                      bgColor = getColorSFRoomBtn(questionData, true);
                    } else if (mainIndex === 7) {
                      bgColor = getColorFurnitureAudit(questionData, true);
                    } else if (mainIndex === 8) {
                      const allReviewed = invoiceProds.every(
                        (prod) => prod.status === "Yes. Reviewed. Good to go."
                      );
                      const red = invoiceProds.some(
                        (prod) =>
                          prod.status === "Yes. Reviewed w/ Open Questions"
                      );
                      if (invoiceProds?.length == 0) {
                        bgColor = defaultGray;
                      } else {
                        bgColor = allReviewed
                          ? greenOP
                          : red
                          ? redOP
                          : yellowOP;
                      }
                    } else if (mainIndex === 9) {
                      const allReviewed = customNotes.every(
                        (prod) => prod.status === "Yes. Reviewed. Good to go."
                      );

                      const red = customNotes.some(
                        (prod) =>
                          prod.status === "Yes. Reviewed w/ Open Questions"
                      );
                      if (customNotes?.length == 0) {
                        bgColor = defaultGray;
                      } else {
                        bgColor = allReviewed
                          ? greenOP
                          : red
                          ? redOP
                          : yellowOP;
                      }
                    } else if (mainIndex === 10) {
                      let currentQues = questionData?.findIndex(
                        (QQues) => QQues.question === ques?.[0]?.question
                      );

                      if (currentQues !== -1) {
                        const allReviewed = openQuestion.every(
                          (prod) => prod.status === "DONE"
                        );

                        let value = questionData[currentQues][ques?.[0]?.key];
                        if (openQuestion?.length == 0) {
                          bgColor =
                            value === "Yes Needed"
                              ? redOP
                              : value === "Not Needed"
                              ? defaultGray
                              : redOP;
                        } else {
                          bgColor = allReviewed ? greenOP : redOP;
                        }
                      } else {
                        bgColor = redOP;
                      }
                    }

                    return (
                      <Accordion
                        disabled={
                          mainIndex === 5 ||
                          mainIndex === 8 ||
                          mainIndex === 9 ||
                          mainIndex === 10
                        }
                        key={mainIndex}
                        sx={{
                          "&.Mui-disabled": {
                            backgroundColor: `#0000 !important`,
                            opacity: 1
                          },
                          "&.MuiPaper-root": {
                            boxShadow: "none !important"
                          }
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            !(
                              mainIndex === 5 ||
                              mainIndex === 8 ||
                              mainIndex === 9 ||
                              mainIndex === 10
                            ) && <ExpandMoreIcon />
                          }
                          sx={{
                            backgroundColor: bgColor,
                            borderRadius: "4px",
                            // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                            borderBottom: "1px solid #343434a1",
                            "&.Mui-disabled": {
                              opacity: 1
                            }
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            {Icon[ques[0]?.question]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start"
                            }}
                          >
                            {ques?.map((q, i) => {
                              return (
                                <Box>
                                  {q?.type === "IMAGE" ? (
                                    <ImagePreview
                                      files={currentQuestData[q?.key]}
                                      handleClick={handleFileClick}
                                      question={q.question}
                                    />
                                  ) : q?.type === "PDF" ? (
                                    <PdfPreview
                                      files={
                                        q?.key?.includes(".")
                                          ? getValueByKey(
                                              currentQuestData,
                                              q.key
                                            ) || []
                                          : currentQuestData[q?.key]
                                      }
                                      handleClick={handlePdfClick}
                                      question={q.question}
                                    />
                                  ) : q.type === "DATE" ? (
                                    <DatePreview
                                      question={q?.question}
                                      date={currentQuestData[q?.key]}
                                    />
                                  ) : (
                                    <DefaultPreview
                                      question={q?.question}
                                      content={
                                        q?.key?.includes(".")
                                          ? getValueByKey(
                                              currentQuestData,
                                              q?.key
                                            )
                                          : currentQuestData[q?.key]
                                      }
                                    />
                                  )}
                                </Box>
                              );
                            })}

                            {/* <Typography variant="body2" sx={{ marginY: "4px" }} >Note:{currentQuestData[q?.key]} </Typography> */}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    );
                  });
                })}
              </Box>
            </Grid>

            <Dialog
              open={openPdf}
              onClose={handleClosePdf}
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>
                PDF Preview
                <IconButton
                  aria-label="close"
                  onClick={handleClosePdf}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500]
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <iframe
                  src={currentPdf}
                  width="100%"
                  height="600px"
                  title="PDF Preview"
                />
              </DialogContent>
            </Dialog>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
              <DialogContent style={customDialogContentStyle}>
                <DialogContentText>
                  <style>{customDotStyles}</style>

                  <Slider className="image-slider-popup" {...settings}>
                    {images?.map((image, index) => (
                      <div
                        key={image + index}
                        className="flex justify-center relative"
                      >
                        <img
                          src={image}
                          alt={`Image ${index}`}
                          className="mx-auto rounded-xl"
                          style={{ width: "730px", height: "665px" }}
                        />
                        <div
                          className={`absolute rounded-full bg-white padding-2.5 top-0 right-0`}
                          // onClick={ }
                          // onTouchStart={(e) => removeImageHandler(e, image)}
                        >
                          <GetIconFile
                            data={{ width: "24px", height: "24px" }}
                            iconName="remove-icon"
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Home;
