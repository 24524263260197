import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  CircularProgress
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { Delete } from "@mui/icons-material";
import DropdownField from "../../question-fields/dropdown";
import NoteField from "../../question-fields/note";
import ImageUploadField from "../../question-fields/ImageUploadField";
import {
  deleteFurniture,
  deleteTransition,
  updateCustomerFurniture,
  updateQuestion,
  updateTransition,
  uploadImage
} from "../../services/finance.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MuiSnackbar from "../../UI/MuiSnackbar";

const FurnitureTable = forwardRef(
  (
    {
      furnitureData,
      zc_po_id,
      customerID,
      setFurnitureData,
      fetchCustomerData,
      fvFloors,
      fvRooms,
      setTransitionConsirmation,
      setIsLoading,
      fieldsToRender,
      questData,
      setQuestData
    },
    ref
  ) => {
    const singleQuestion = {
      item: "",
      note: "",
      images: [],
      zc_po_id: zc_po_id,
      qty: "",
      size: "",
      in_scope: false
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [type, setType] = React.useState("");
    const [isApiCall, setIsApiCall] = React.useState(false);

    const floorOptions = useMemo(() => {
      return fvFloors.map((floor) => floor.Name);
    }, [fvFloors]);

    const handleAddQuestion = () => {
      if (!fvFloors?.length) {
        setOpen(true);
        setMessage("No floors available");
        setType("error");
        return;
      }
      setFurnitureData([
        ...furnitureData,
        {
          ...singleQuestion,
          floorId: furnitureData[furnitureData?.length - 1]?.floorId || "",
          roomId: furnitureData[furnitureData?.length - 1]?.roomId || ""
        }
      ]);
    };

    const handleImageChange = async (selectedFiles, index) => {
      setIsLoading(true);

      let formData = new FormData();
      formData.append("file", selectedFiles[0]);
      formData.append("originalname", selectedFiles[0]?.name);

      let imageUploaded = await uploadImage(formData);
      if (imageUploaded.data.status) {
        handleChanges(
          "images",
          [...furnitureData[index]?.images, imageUploaded?.data?.entity],
          null,
          index
        );
        setIsLoading(false);
      }
    };

    const handleChanges = async (field, value, prod, index) => {
      const updatedProdsArray = furnitureData.map((item, idx) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      });

      setFurnitureData(updatedProdsArray);
    };

    const handleDelete = (index) => {
      setDeleteIndex(index);
      setOpenDialog(true);
    };

    const confirmDelete = async () => {
      if (furnitureData?.[deleteIndex]?.id) {
        let response = await deleteFurniture(furnitureData?.[deleteIndex]?.id);
        if (response?.data?.status) {
          const updatedProdsArray = furnitureData.filter(
            (_, idx) => idx !== deleteIndex
          );

          setFurnitureData(updatedProdsArray);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        const updatedProdsArray = furnitureData.filter(
          (_, idx) => idx !== deleteIndex
        );

        setFurnitureData(updatedProdsArray);
        setIsLoading(false);
      }

      setOpenDialog(false);
      setDeleteIndex(null);
    };

    useEffect(() => {
      setFurnitureData(furnitureData);
    }, []);

    const handleSubmit = async () => {
      setIsApiCall(true);
      if (furnitureData?.length > 0) {
        const response = await updateCustomerFurniture(zc_po_id, {
          furnitures: furnitureData
        });

        if (response?.status) {
          setFurnitureData(response?.data?.entity);
        } else {
          console.log("response", response);
          toast.error(response?.message || "Something went wrong");
        }
      } else {
        toast.error("Please Add Furniture");
      }
      setIsApiCall(false);
    };

    const handleRemoveFiles = async (file, index) => {
      setIsLoading(true);
      let transitions = furnitureData;
      if (furnitureData?.[index]?.id) {
        let filteredImages = transitions[index]?.images.filter(
          (files, idx) => files !== file
        );

        transitions[index].images = filteredImages;

        const allReviewed = transitions.every(
          (prod) => prod.status === "Reviewed and Approved"
        );
        const response = await updateCustomerFurniture(zc_po_id, {
          furnitures: furnitureData
        });
        if (response?.furnitureData?.status) {
          setFurnitureData(response?.data?.entity);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        let filteredImages = transitions?.images.filter(
          (files, idx) => files !== file
        );
        transitions.images = filteredImages;
        setFurnitureData(transitions);
        setIsLoading(false);
      }
    };

    const columns = [
      { id: (_prod, idx) => idx + 1, label: "NO.", maxWidth: 1 },
      {
        id: (prod, index) => {
          let floorName = fvFloors?.find((floor) => floor?.id == prod?.floorId);

          let roomOption = [];
          if (prod?.floorId) {
            roomOption = fvRooms
              ?.filter((room) => room?.Field_Visit_Floor?.id == floorName?.id)
              ?.map((rm) => rm?.Display_Name);
          } else {
            // handleChanges("roomId", "", prod, index);
            roomOption = [];
          }
          let roomName = fvRooms?.find((room) => room?.id == prod?.roomId);

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px" // Adjust gap between dropdowns as needed
              }}
            >
              {/* Dropdown for selecting Floor */}
              <DropdownField
                question={""}
                value={floorName?.Name || ""}
                options={floorOptions}
                handleChangeDropdown={(event) => {
                  let floor = fvFloors?.find(
                    (rm) => rm?.Name === event.target.value
                  );
                  handleChanges("floorId", floor?.id, prod, index);
                }}
              />

              {/* Dropdown for selecting Room */}
              <DropdownField
                question={""}
                value={roomName?.Display_Name || ""}
                options={roomOption}
                handleChangeDropdown={(event) => {
                  let roomId = fvRooms?.find(
                    (rm) => rm?.Display_Name === event.target.value
                  );

                  handleChanges("roomId", roomId?.id, prod, index);
                }}
              />
            </div>
          );
        },
        label: "Place",
        align: "center",
        maxWidth: 40
      },
      {
        id: (prod, index) => {
          let options = [
            "Side Table",
            "Ottoman",
            "Dining Chairs",
            "Buffer Table",
            "Chairs/Stools",
            "Nightstands",
            "Desk Chair",
            "Coffee Table",
            "Bookshelf",
            "Ent. Center(A/D)",
            "TV Stand",
            "Dining Table",
            "Kitchen Table",
            "Desk",
            "Sofa",
            "Sofa W/Pullout",
            "Bed(A/D)",
            "Bunk Bed(A/D)",
            "Dresser",
            "Other"
          ];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <DropdownField
                question={""}
                value={prod?.item || ""}
                options={options}
                handleChangeDropdown={(event) =>
                  handleChanges("item", event.target.value, prod, index)
                }
              />
            </div>
          );
        },
        label: "Item",
        align: "center",
        maxWidth: 50
      },
      {
        id: (prod, index) => {
          let options = ["Small", "Medium", "Large"];
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <DropdownField
                question={""}
                value={prod?.size || ""}
                options={options}
                handleChangeDropdown={(event) =>
                  handleChanges("size", event.target.value, prod, index)
                }
              />
            </div>
          );
        },
        label: "Size",
        align: "center",
        maxWidth: 50
      },

      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <NoteField
                question={""}
                value={prod?.qty || ""}
                handleNoteBlur={(event) =>
                  handleChanges("qty", event.target.value, prod, index)
                }
                type={"text"}
              />
            </div>
          );
        },
        label: "Quantity",
        align: "center",
        maxWidth: 60
      },
      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4
              }}
            >
              <NoteField
                question={""}
                value={prod?.note || ""}
                multiline={true}
                maxRows={5}
                minRows={1}
                handleNoteBlur={(event) =>
                  handleChanges("note", event.target.value, prod, index)
                }
                type={"text"}
              />
            </div>
          );
        },
        label: "Notes",
        align: "center",
        maxWidth: 60
      },
      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 4
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} key={prod?.question + index}>
                  <ImageUploadField
                    handleImageChange={(selectedFiles) =>
                      handleImageChange(selectedFiles, index)
                    }
                    width="calc(100% - 158px)"
                    name="imageUpload"
                    id="imageUpload"
                    files={prod.images || []}
                    accept="image/*"
                    type={"IMAGE"}
                    imgUploading={false}
                    handleRemoveFiles={(file) => handleRemoveFiles(file, index)}
                  />
                </Grid>
              </Grid>
            </div>
          );
        },
        label: "Images",
        align: "center",
        minWidth: 70
      },
      {
        id: (prod, index) => (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 4
            }}
          >
            <Checkbox
              checked={prod.in_scope || false}
              onChange={(e) =>
                handleChanges("in_scope", e.target.checked, prod, index)
              }
            />
          </div>
        ),
        label: "In Scope",
        align: "center",
        maxWidth: 20
      },
      {
        id: (prod, index) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <IconButton onClick={() => handleDelete(index)}>
                <Delete />
              </IconButton>
            </div>
          );
        },
        label: "Delete",
        align: "center",
        maxWidth: 5
      }
    ];

    console.log("value--1", furnitureData);
    return (
      <Box sx={{ width: "96vw", padding: "12px", margin: "auto" }}>
        <ToastContainer />
        <MuiSnackbar
          open={open}
          message={message || ""}
          type={type || ""}
          onClose={() => setOpen(false)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddQuestion}
          startIcon={<AddIcon />}
          sx={{ marginBottom: "16px" }}
        >
          Add Items
        </Button>
        <Grid container spacing={2} sx={{ paddingTop: 1 }}>
          <Grid xs={12} md={12}>
            <Paper
              sx={{
                width: "100%",
                overflow: "auto",
                border: "1px solid #6685ac"
              }}
            >
              <TableContainer
                style={{
                  maxHeight: "calc(100vh - 130px)",
                  overflowY: "auto"
                }}
              >
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{ borderCollapse: "collapse" }}
                >
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.label}
                          align={column.align}
                          style={{
                            maxWidth: column.maxWidth,
                            minWidth: column.minWidth,
                            fontWeight: "bold"
                          }}
                          sx={{
                            border: "0.5px solid #6685ac",
                            backgroundColor: "#dae4f0"
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {furnitureData?.length > 0 ? (
                      furnitureData?.map((prod, idx) => (
                        <TableRow tabIndex={-1} key={prod.id || idx}>
                          {columns.map((column) => {
                            const value = prod[column.id];
                            const columnId = column.id;
                            const valueToRender =
                              typeof columnId === "function"
                                ? columnId(prod, idx)
                                : value;
                            return (
                              <TableCell
                                key={column.label}
                                align={column.align}
                                style={{
                                  maxWidth: column.maxWidth,
                                  borderColor: "#6685ac",
                                  border: "0.5px solid #6685ac"
                                }}
                              >
                                {column.format &&
                                typeof valueToRender === "number"
                                  ? column.format(valueToRender)
                                  : valueToRender}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={8}
                          sx={{
                            textAlign: "center",
                            verticalAlign: "middle",
                            height: "100px"
                          }}
                        >
                          No Furniture
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Furniture?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmDelete} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            width: "fit-content",
            padding: "16px"
          }}
        >
          <Button
            variant="contained"
            sx={{
              gap: "10px"
            }}
            color="primary"
            disabled={isApiCall || furnitureData?.length === 0} // Correct prop: 'disabled'
            onClick={handleSubmit}
          >
            Submit
            {isApiCall && <CircularProgress size={20} color="inherit" />}
          </Button>
        </Box>
      </Box>
    );
  }
);

export default FurnitureTable;
